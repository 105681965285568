<template>
  <v-layout justify-center align-center d-flex>
    <v-col cols="12">
      <v-card elevation="0" max-width="100%">
        <v-card-title class="display-1 text--primary">
          {{ $t('notifications.titles.notifications') }}
        </v-card-title>
      </v-card>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-card elevation="0" max-width="100%" width="100%">
              <v-card-text class="pa-0">
                <v-list
                  dense
                  class="
                    d-flex
                    justify-start
                    align-center align-content-center
                    secondary
                  "
                  rounded
                >
                  <v-list-item dense style="max-width: fit-content">
                    <v-list-item-icon class="mx-2 px-0">
                      <v-icon class="t-bw-secondary--text"> mdi-email-open-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="ma-0 pa-0 t-bw-secondary--text">
                        {{ $t('notifications.titles.read') }}: <strong>{{ watched }}</strong>
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item dense style="max-width: fit-content">
                    <v-list-item-icon class="mx-2 px-0">
                      <v-icon class="t-bw-secondary--text"> mdi-email-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="ma-0 pa-0 t-bw-secondary--text">
                        {{ $t('notifications.titles.unread') }}: <strong>{{ unwatched }}</strong>
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item dense style="max-width: fit-content">
                    <v-list-item-icon class="mx-2 px-0">
                      <v-icon class="t-bw-secondary--text"> mdi-inbox-full-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="ma-0 pa-0 t-bw-secondary--text">
                        {{ $t('notifications.titles.all') }}: <strong>{{ totalFromServer }}</strong>
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item dense style="max-width: fit-content">
                    <v-list-item-action class="mx-1 px-0 ">
                      <ConfirmDialog
                        :loading="loadingArchiveAll"
                        :is-btn="true"
                        :btn-title="alText"
                        :text="false"
                        :w-icon="true"
                        :i-left="true"
                        :elevation="0"
                        :small="true"
                        btn-color="t-bw-primary--text"
                        i-color="t-bw-primary--text"
                        btn-icon="mdi-archive-arrow-down-outline"
                        action="archiveAll"
                        :valid="!(totalFromServer > 0)"
                      />
                    </v-list-item-action>
                    <v-list-item-action class="mx-1 px-0">
                      <ConfirmDialog
                        :loading="loadingRead"
                        :is-btn="true"
                        :btn-title="raText"
                        :text="false"
                        :w-icon="true"
                        :i-left="true"
                        :elevation="0"
                        :small="true"
                        btn-color="t-bw-primary--text"
                        i-color="t-bw-primary--text"
                        btn-icon="mdi-check-all"
                        action="readAll"
                        :valid="!(totalFromServer > 0 && enableRA)"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="0" style="position: fixed" max-width="100%" width="20%" class="secondary">
              <v-card-title class="px-6 t-bw-secondary--text py-5">
                <h4>{{ $t('notifications.titles.filters') }}</h4>
                <v-spacer />
                <v-badge color="neuter" :content="filterCount" :value="filterCount > 0" light offset-x="20" overlap>
                  <v-icon left color="t-bw-secondary--text">
                    mdi-filter-settings-outline
                  </v-icon>
                </v-badge>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card elevation="0"
                    v-if="notifications && notifications.length > 0">
              <v-overlay opacity=".8" absolute color="white" v-if="loadingArchiveAll || loadingRead">
                <v-progress-circular indeterminate color="primary"  />
              </v-overlay>
              <v-list
                outlined
                class="pa-0"
                style="border-radius: .4em; overflow-y: auto; height: 60vh"
                :style="[{ 'overflow-y: auto; height: auto !important' : notifications.length < 5 }]"
              >
                <v-list-item
                  ripple
                  selectable
                  v-for="(notification, index) in notifications"
                  :class="[
                  { 'notification-border__read': notification.watched },
                  { 'notification-border__unread': !notification.watched },
                ]"
                  style="border-bottom: 1px solid rgba(0,0,0,0.07); height: 12vh"
                  class="mr-0 pr-0 notification-border__options"
                  @mouseleave="$set(iconOptions, index, false)" @mouseenter="$set(iconOptions, index, true)"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        color="primary"
                        :input-value="selected.some(item => item.id === notification.id)"
                        @change="(e) => toggleSelection(notification, e)"
                      >
                        <template v-slot:append>
                          <v-icon :color="setIconPriority(notification.priority).color">
                            {{ setIconPriority(notification.priority).icon }}
                          </v-icon>
                        </template>
                      </v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <BrainAgNotification
                        v-if="notification.module === 'BRAIN_AG'"
                        :index="index"
                        :loading="loading[notification.id]"
                        :notification="notification"
                        :with-action="false"
                        @mark:read="markRead(notification)"
                      />
                      <RecordsNotification
                        v-if="notification.module === 'RECORDS' && !isExport(notification)"
                        :index="index"
                        :loading="loading[notification.id]"
                        :notification="notification"
                        :with-action="false"
                        @mark:read="markRead(notification)"
                      />
                      <RecordValidationNotification
                        v-if="notification.module === 'RECORD_VALIDATION'"
                        :index="index"
                        :loading="loading[notification.id]"
                        :loadingSee="loadingSee[notification.id]"
                        :notification="notification"
                        :with-action="false"
                        @see:record="seeRecord(notification)"
                        @mark:read="markRead(notification)"
                      />
                      <TraceabilityNotification
                        v-if="notification.module === 'TRACEABILITY'"
                        :index="index"
                        :loading="loading[notification.id]"
                        :notification="notification"
                        :with-action="false"
                        @mark:read="markRead(notification)"
                      />
                      <TraceabilityErrorNotification
                        v-if="notification.module === 'TRACEABILITY_ERROR'"
                        :index="index"
                        :loading="loading[notification.id]"
                        :notification="notification"
                        :with-action="false"
                        @mark:read="markRead(notification)"
                      />
                      <ImportRecordNotification
                        ref="recordImportFile"
                        v-if="notification.module === 'EXCEL_IMPORT'"
                        :index="index"
                        :loading="loading[notification.id]"
                        :notification="notification"
                        @mark:read="markRead(notification)"
                        :with-action="false"
                      />
                      <S3FileNotification
                        ref="s3File"
                        v-if="isExport(notification)"
                        :index="index"
                        :loading="loading[notification.id]"
                        :notification="notification"
                        :with-action="false"
                        @mark:read="markRead(notification)"
                      />
                      <StockNotification
                        v-if="notification.module === 'STOCK'"
                        :index="index"
                        :loading="loading[notification.id]"
                        :notification="notification"
                        :with-action="false"
                        @mark:read="markRead(notification)"
                      />
                      <UserLocationsNotification
                        v-if="notification.module === 'USER_LOCATIONS'"
                        :index="index"
                        :loading="loading[notification.id]"
                        :notification="notification"
                        :with-action="false"
                        @mark:read="markRead(notification)"
                      />
                    </v-list-item-content>

                    <v-list-item-action class="mx-0 px-0">
                      <v-btn tile block max-height="100%" height="12vh" elevation="0" disabled>
                        <v-icon>{{ iconOptions[index] ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <span class="notification__animation d-flex justify-space-between">
                    <v-list-item-action
                      v-if="notification.module === 'RECORD_VALIDATION'"
                      class="mx-0 px-0"
                    >
                      <v-btn tile
                             block
                             text
                             max-height="100%"
                             height="12vh"
                             elevation="0"
                             color="primary"
                             @click="$root.$emit('show:record', notification)">
                        {{ $t('common.buttons.show') }}
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-action
                      v-if="isExport(notification) || isDownloadable(notification)"
                      class="mx-0 px-0"
                    >
                      <v-btn tile
                             block
                             text
                             max-height="100%"
                             height="12vh"
                             elevation="0"
                             color="primary"
                             :loading="loadingDownload"
                             @click="downloadFileFromS3(notification, index)">
                        {{ $t('common.buttons.download') }}
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-action class="mx-0 px-0">
                      <ConfirmDialog
                        :styles="'mx-0'"
                        :item="notification"
                        :tile="true" :block="true" max-height="100%" height="12vh"
                        :loading="loadingD[notification.id]"
                        :btn-title="$t('notifications.titles.archive')"
                        :is-tooltip="false"
                        :is-btn="true"
                        :is-icon="false"
                        action="archiveN"
                        :frBtnProps="{
                          small: true,
                          color: 'error',
                          textColor: 't-bw-error--text',
                          elevation: 0,
                          text: false
                        }"
                      />
                    </v-list-item-action>
                  </span>
                  </template>
                </v-list-item>
              </v-list>
            </v-card>

            <v-list
              outlined
              class="pa-0"
              style="border-radius: .4em"
              v-else
            >
              <v-list-item class="d-flex justify-center">
                {{ $t('common.ui.not_data_found') }}
              </v-list-item>
            </v-list>
            <v-card-actions class="d-flex justify-center">
              <v-pagination
                :disabled="totalFromServer <= 10"
                color="primary"
                v-model="defaultFilters.pageableDTO.page"
                :length="Math.ceil(totalFromServer / 10)"
                @input="fetchNotifications([defaultFilters, $toast], $event)"
                :total-visible="10"
              ></v-pagination>
            </v-card-actions>
          </v-col>
          <v-col cols="4">
            <NotificationFilters @set:filters="setFilters($event)" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-col>

  </v-layout>
</template>

<script>
  import { mapActions, mapGetters } from "vuex"
  import i18n from "@/plugins/i18n"
  import StockNotification from "@/module/configuration/notifications/components/types/StockNotification.vue"
  import BrainAgNotification from "@/module/configuration/notifications/components/types/BrainAgNotification.vue"
  import TraceabilityNotification from "@/module/configuration/notifications/components/types/TraceabilityNotification.vue"
  import UserLocationsNotification from "@/module/configuration/notifications/components/types/UserLocationsNotification.vue"
  import RecordsNotification from "@/module/configuration/notifications/components/types/RecordsNotification.vue"
  import _ from "lodash"
  import router from "@/router"
  import RecordValidationNotification from "@/module/configuration/notifications/components/types/RecordValidationNotification.vue"
  import NotificationFilters from "@/module/configuration/notifications/components/NotificationFilters.vue"
  import NotificationService from "@/services/NotificationService";
  import ConfirmDialog from "@/components/ConfirmDialog.vue";
  import S3FileNotification from "@/module/configuration/notifications/components/types/S3FileNotification.vue";
  import axios from "axios";
  import TraceabilityErrorNotification
    from "@/module/configuration/notifications/components/types/TraceabilityErrorNotification.vue";
  import ImportRecordNotification
    from "@/module/configuration/notifications/components/types/ImportRecordNotification.vue"

  export default {
    name: "IndexView",
    components: {
      ImportRecordNotification,
      TraceabilityErrorNotification,
      ConfirmDialog,
      NotificationFilters,
      RecordValidationNotification,
      RecordsNotification,
      UserLocationsNotification,
      TraceabilityNotification,
      BrainAgNotification,
      StockNotification,
      S3FileNotification,
    },

    data: () => ({
      loading: {},
      loadingSee: {},
      loadingD: {},
      loadingAll: false,
      loadingRead: false,
      loadingDownload: false,
      loadingArchiveAll: false,
      loadingFetchAll: false,
      userInitiatedPageChange: false,
      iconOptions: {},
      enableRA: false,
      defaultFilters: {
        modules: null,
        priorities: null,
        watched: null,
        pageableDTO: {
          page: 1,
          size: 10,
          sortBy: "dateTime",
          direction: "DESC",
        },
      },
      isRead: true,
      selected: [],
      actionDialog: null
    }),

    async created() {
      await this.fetchNotifications([this.defaultFilters, this.$toast], 1)
    },

    watch: {
      selected() {
        if (this.selected.length > 0)
          this.enableRA = _.filter(this.selected, { watched: false }).length > 0;
        else {
          this.enableRA = _.filter(this.notifications, { watched: false }).length > 0;
        }
      },
      notifications() {
        this.enableRA = _.filter(this.notifications, { watched: false }).length > 0;
      }
    },

    mounted() {
      this.$root.$on('archiveN', (n) => {
        this.archiveN(n)
      })

      this.$root.$on('archiveAll', () => {
        this.archiveAll()
      })

      this.$root.$on('readAll', () => {
        this.readAll()
      })
    },

    computed: {
      ...mapGetters({
        notifications: "notifications/notifications",
        watched: "notifications/watched",
        unwatched: "notifications/unwatched",
        totalFromServer: "notifications/totalFromServer",
      }),
      isExport() {
        return (notification) => (notification.data && notification.data.type) && ['PDF_EXPORT', 'EXCEL_EXPORT'].includes(notification.data.type);
      },
      isDownloadable() {
        return (notification) => ['EXCEL_IMPORT'].includes(notification.module) && !notification.success;
      },
      alText() {
        return this.selected.length > 0 ? i18n.t('notifications.titles.archiveSelected') : i18n.t('notifications.titles.archiveAll')
      },
      raText() {
        return this.selected.length > 0 ? i18n.t('notifications.titles.readSelected') : i18n.t('notifications.titles.readAll')
      },
      filterCount() {
        let count = 0;

        for (let key in this.defaultFilters) {
          if (key !== 'pageableDTO') {
            if (Array.isArray(this.defaultFilters[key])) {
              // Para las propiedades que son arreglos, considera que están llenas si su longitud es mayor a 0
              if (this.defaultFilters[key].length > 0) {
                count++;
              }
            } else {
              // Para las propiedades que no son arreglos, solo verifica que no sean null
              if (this.defaultFilters[key] !== null) {
                count++;
              }
            }
          }
        }

        return count;
      },
    },

    methods: {
      ...mapActions({
        setReadStatus: "notifications/setReadStatus",
        findLogRecordById: "records/findLogByRecordId",
        findRecordById: "records/findRecordById",
      }),
      async downloadFileFromS3(notification, index) {
        let filename = 'download';
        let url = null;

        console.log(notification)

        if (notification.data)
          switch (notification.data.type) {
            case 'PDF_EXPORT':
              url = notification.data.s3Url;
              filename = `${this.$refs.s3File[index].getTranslationModule(notification.module).toLowerCase()}.pdf`;
              break
            case 'EXCEL_EXPORT':
              url = notification.data.s3Url;
              filename = `${this.$refs.s3File[index].getTranslationModule(notification.module).toLowerCase()}.xlsx`;
              break
          }

        switch (notification.module) {
          case 'EXCEL_IMPORT':
            url = notification.s3Url;
            filename = `${this.$t('records.label').toLowerCase()}.xlsx`;
            break
        }

        if (url) {
          await axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
          })
            .then(response => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', filename);
              document.body.appendChild(fileLink);

              fileLink.click();

              document.body.removeChild(fileLink);
              window.URL.revokeObjectURL(fileURL);
            })
            .catch(error => {
              console.error('Error al descargar el archivo: ', error);
            });
        }
      },
      showRecord(id) {
        this.$root.$emit('see:record', id)
      },
      setIconPriority(priority) {
        switch (priority) {
          case "LOW":
          case "NORMAL":
            return {
              icon: 'mdi-minus',
              color: 'success'
            }
          case "MEDIUM":
            return {
              icon: 'mdi-equal',
              color: 'warning'
            }
          case "HIGH":
            return {
              icon: 'mdi-chevron-double-up',
              color: 'error'
            }
        }
      },
      toggleSelection(n, vCheckbox) {
        const index = this.selected.findIndex(item => item.id === n.id);

        if(vCheckbox && index === -1) {
          this.selected.push(n);
        }
        else if (!vCheckbox && index > -1) {
          this.selected.splice(index, 1);
        }
      },
      async archiveN(n) {
        this.$set(this.loadingD, n.id, true)

        await NotificationService.deleteNotifications([n.id])
          .then(() => {
            this.fetchNotifications([this.defaultFilters, this.$toast], 1)
            this.$store.dispatch('notifications/fetchNotificationsMenuByUser', [this.$toast])
          })
          .catch(() => {
            this.$toast.error(i18n.t('notifications.titles.archiveError'), {
              queueable: true,
            })
          })
          .finally(() => {
            this.$set(this.loadingD, n.id, false)
          })
      },
      async archiveAll() {
        this.loadingArchiveAll = true

        let _ids = []
        if (this.selected.length > 0) _ids = _.map(this.selected, s => s.id)

        await NotificationService.deleteNotifications(_ids)
          .then(() => {
            this.fetchNotifications([this.defaultFilters, this.$toast], 1)
            this.$store.dispatch('notifications/fetchNotificationsMenuByUser', [this.$toast])
          })
          .catch(() => {
            this.$toast.error(i18n.t('notifications.titles.archiveError'), {
              queueable: true,
            })
          })
          .finally(() => {
            this.loadingArchiveAll = false
            this.$set(this, 'selected', [])
            this.$set(this.defaultFilters.pageableDTO, 'page', 1)
        })
      },
      async readAll() {
        this.loadingRead = true

        let _ids = []
        if (this.selected.length > 0) _ids = _.map(this.selected, s => s.id)

        await this.setReadStatus([_ids, this.$toast]).finally(() => {
          this.loadingRead = false
        })

        await this.fetchNotifications([this.defaultFilters, this.$toast], 1).finally(() => {
          this.$set(this, 'selected', [])
          this.$set(this.defaultFilters.pageableDTO, 'page', 1)
        })
      },
      async setFilters(e) {
        if (e.watched)
          this.defaultFilters.watched = e.watched
        else
          this.defaultFilters.watched = null

        if (e.modules) {
          let modules = []
          _.forEach(e.modules, (m) => {
            if (m instanceof Array)
              modules = [...modules, ...m]
            else
              modules.push(m)
          })
          this.defaultFilters.modules = modules.includes('ALL') ? null : modules
        }

        if (e.priorities)
          this.defaultFilters.priorities = (!e.priorities || e.priorities.length === 0) ? null : e.priorities

        await this.fetchNotifications([this.defaultFilters, this.$toast], 1)
      },
      async fetchNotifications(filters, e) {
        let [params, toast] = filters

        if (e)
          params = {
            ...params,
            pageableDTO: {
              page: e - 1,
              size: 10,
              sortBy: "dateTime",
              direction: "DESC",
            },
          }

        this.loadingAll = true
        await this.$store
          .dispatch("notifications/fetchNotificationsByUser", [params, toast])
          .then(() => {
            this.loadingAll = false
          })
          .catch(() => {
            this.loadingAll = false
          })
      },
      async markRead(item) {
        const id = _.cloneDeep(item.id)
        this.$set(this.loading, id, true)

        await this.setReadStatus([[id], this.$toast]).finally(() => {
          this.$set(this.loading, id, false)
        })
      },
      async seeRecord(notification) {
        if (router.currentRoute.name !== "records")
          await router.push({ name: "records" })

        this.$set(this.loadingSee, notification.id, true)

        const promises = []
        promises.push(this.findRecordById([notification.recordId, this.$toast]))
        promises.push(
          this.findLogRecordById([notification.recordId, this.$toast])
        )

        Promise.all(promises).finally(() => {
          this.$set(this.loadingSee, notification.id, false)
          this.$store.commit("records/SET_DIALOG_DETAILS", true)
        })
      },
    },

    destroyed() {
      this.$root.$off('archiveN')
      this.$root.$off('archiveAll')
      this.$root.$off('readAll')
    }
  }
</script>

<style scoped lang="scss">
  :deep(.v-pagination__item) {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.13);
  }
  :deep(.v-pagination__navigation) {
    box-shadow: none !important;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    i {
    color: white;
  }

  :deep(.v-icon.all) {
    color: var(--v-primary-base) !important;
  }

  .notification-border__read {
    border-left: 7px solid #eeeeee;
  }

  .notification-border__unread {
    border-left: 7px solid var(--v-primary-base);
  }

  :deep(span.notification__animation) {
    overflow: hidden !important;
  }

  .notification-border__options {
    .notification__animation {
      max-width: 0;
      transition: all .2s ease-in-out;
    }

    .v-btn {
      position: relative;
      left: 0;
      transition: all .2s ease-in-out;
    }

    &:hover {
      .notification__animation {
        max-width: 100%;
      }
      .v-btn {
        left: 0;
      }
    }
  }

  .tooltip__style {
    background-color: black !important;
  }

  :deep(.v-badge__badge) {
    color: var(--v-primary-base);
    font-weight: 600 !important;
  }
</style>
